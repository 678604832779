import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../../components/layout';
import styles from '../../components/page.module.css';

export default ({ data }) => {
  const items = data.allMarkdownRemark;
  return (
    <Layout>
      <Helmet>
        <title>Skills</title>
      </Helmet>
      <h2>Skills</h2>
      {items.edges.map((item) => (
        <div className={styles.container}>
          <span className={styles.timenode}/>
          <h4 className={styles.header}>
            {item.node.frontmatter.title}
          </h4>
          <div className={styles.content}>
            <h5 className={styles.subheader}>
              {item.node.frontmatter.type}
            </h5>
            <h5 className={styles.daterange}>
              {item.node.frontmatter.date}
            </h5>
            <div dangerouslySetInnerHTML={{ __html: item.node.html }} />
          </div>
        </div>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC },
      filter: { frontmatter: { category: { eq: "skills" } } }
    ) {
    totalCount
    edges {
      node {
        id
        html
        frontmatter {
          category
          title
          date(formatString: "DD MMMM, YYYY")
        }
        excerpt
        fields {
          slug
        }
      }
    }
  }
}
`
